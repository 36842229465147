import { Component, Prop, Vue } from 'vue-property-decorator';


import ChartToneLine from './chart-tone-line';

import store from '@/services/store';

export interface IShownData {
    type: string;
    data: any | null;
}

@Component({
    components: {
        'chart-tone-line': ChartToneLine
    }
})
export default class CContentChartToneLine extends Vue {
    @Prop({
        required: true,
        default: () => {
            return { value: 1 };
        }
    })
    private period!: any; // выбранный период

    @Prop({
        type: Boolean,
        required: false,
        default: false
    })
    public readonly full!: boolean;

    public datas: any = null;


    private mounted() {
        this.loadData();
    }

    public code: any = store.state._instanceCode;

    public get selectedMap(): any {
        let ajxParams;
        if (this.code == '' || this.code == 55) {
            ajxParams = {
                'params': {
                    'type': 1,
                    'period': 7,
                    'selectedPeriod': null,
                    'keywords': 'Павлодар* | Pavlodar* | Аксу* | Ақсу* | Aqsu*| (Ақсу* + қала*) | Экибастуз* | Екібастұз*| Ekibastuz* | (Экибастуз* + город*) | (Екібастұз* + қала*) | Ақтоғай*| Актогай* | Aqtogai* | Aqtogay* | Aktogai*| (Актогайск* + район*) | (Ақтоғай* + аудан*) | Баянауыл*| Баянаульск*| Bayanauyl* | (Баянаульск* + район*) | (Баянауыл* + аудан*) | (Железинск* + район*) | (Железин* + аудан*) | (Иртышск* + район*) | (Ертіс* + аудан*) | (Качирск* + район*) | (Качиры* + аудан*) | (Район* + Тереңкөл*) | (Район* + Теренкол*) | (Тереңкөл* + аудан*) | (Лебяжинск* + район*) | (Лебяжі* + аудан*) | (Район* + Аққулы*) | (Район* + Аккулы*) | (Аққулы* + аудан*) | (Майск* + район*) | (Май* + аудан*) | (Павлодарск* + район*) | (Павлодар + аудан*) | (Успенск* + район*) | (Успен + ауданы*) | (Щербактинск* + район*) | (Шарбақты* + аудан*) | (Байханов* + Асаин*) | (Байхан* + Асайын*) | (Асаин* + Куандыкович*) | (Асайын* + Қуандықұлы*) |(Скаков* + Абылкаир*) | (Сқақов* + Әбілқайыр*)',
                    'attrSearch': false,
                    'tone': null
                }
            };
        } else if (this.code == 23) {
            ajxParams = {
                'params': {
                    'type': 1,
                    'period': 7,
                    'selectedPeriod': null,
                    'keywords': 'Атырау*| Atyrau* | atyrau* |(Атырау*+қала*) | (Атырауск* + обл*) | (город* Атырау*) | (Жылыой* район*) | (Жылыой*+аудан*) | (Құлсары*) | (Кульсары*+ город*) | (Индер*+аудан*)| (Индерск*+район*)| Индерборск*| (Исатайск*+район*)| (Исатай*+аудан*) | Аккистау*| Аққыстау*| (Кзылкогинск*+ район*) | (Қызылқоға*+ауданы*) | Миялы*|(Курмангазинск*+район*)|(Құрманғазы*+ауданы*)|(Макатск* + район*)|(Мақат*+ауданы*)|(Махамбетск*+район*)|(Махамбет*+аудан*)|(Серик*+ Шапкенов*)|(Шәпкенов*+ Серік*)',
                    'attrSearch': false,
                    'tone': null
                }
            };
        } else if (this.code == 11) {
            ajxParams = {
                'params':
                {
                    'type': 1,
                    'period': 7,
                    'selectedPeriod': null,
                    'keywords': 'Акмола| Aqmola|Кокшетау|Көкшетау| (Ақкөл+ аудан) | (Аккол+ район) | Акколь| Aqkol |Алексеевка| (Аршалы+ аудан) |(Аршалын+район) | Вишнев| (Аршалы+Вишнёвка)|(Астрахан+ аудан)|(Астрахан+район)|Астраханка| (Атбасар+ аудан) | (Атбасар+район) |Атбасар | Atbasar* | (Бұланды+ аудан) |(Буланды+ район)|Макинский Макинск|(Бурабай+ аудан) | Боровое| (Бурабайск+ район) | Щучинск* | (Щучинск+ аудан) | (Егіндікөл+ аудан) | (Егиндыкол+ район) | Краснознамен* |(Біржан+сал+ аудан) | (район+ Биржан* +сал) | Енбекшильдер | Степняк|(Ерейментау+ аудан)|(Ерейментауск+район)|Ерейментау|(Есіл+ аудан) | ( Есильск+район) |  Есил* |(Жақсы+ аудан) | (Жаксынск+район) | Жаксы| (Жарқайың+ аудан) | (Жаркаинск+ район) |Державинский| Державинск|(Зеренді+аудан) |(Зерендинск+ район) | Зеренда|(Қорғалжын+ аудан) |(Коргалжынск+ район) | Коргалжын|(Сандықтау+аудан) | (Сандыктауск+район)| Балкашинск| Балкашино* |(Целиноград+ аудан) | (Целиноградск+район) | Аккол* |(Шортанды+ аудан) | (Шортандинск+район)|Шортанды|(город+Кокшетау)|Кокшетау|Kokshetau* |( город+ Степногорск)| Ильинка| Степногорск',
                    'attrSearch': false,
                    'tone': null
                }
            };
        } else if (this.code == 35) {
            ajxParams = {
                'params':
                {
                    'type': 1,
                    'period': 7,
                    'selectedPeriod': null,
                    'keywords': 'Караганда* | Karaganda* | Балхаш* | Balkhash* | Балқаш*| (Балқаш* + қала*) | Приозёрск* | Приозерск* | Priozersk* | Сарань* | Саран* | Saran* | Темиртау* | Temirtau* | Теміртау* | Шахтинск* | Shakhtinsk* | Шахтинск* | Абай*| Абайский* | Abay* | Abai* | (Абайск* + район*) | (Абай* + аудан*) | Бухар-Жырау*| Бухар* + Жырау**| Bukhar-Zhyrau* | Bukhar* + Zhyrau* | (Бухар-Жырауск* + район*) | (Бұқар + Жырау* + аудан*) | (Каркаралинск* + район*) | (Қарқаралы* + аудан*) | (Нуринск* + район*) | (Нұра* + аудан*) | (Район* + Осакаровск*) | (Осакаров* + аудан*)',
                    'attrSearch': false,
                    'tone': null
                }
            };
        } else if (this.code == 47) {
            ajxParams = {
                'params':
                {
                    'type': 1,
                    'period': 7,
                    'selectedPeriod': null,
                    'keywords': 'Мангистау* | Маңғыстау* | Mangystau* | Мангыстау* | (Мангыстау*+обл*) | (Маңғыстау*+обл*)| Aktau*| Ақтау* | Актау* | (город*+Актау*)| (Ақтау*+ қала*) | Жанаозен* | Жаңаөзен* | Zhanaozen*| (Жаңаөзен*+ қала*)| (Бейнеуск* + район*) |(Бейнеу* + аудан*) | Beyneu* | (Каракиянск* + район*)| (Қарақия* + аудан*) | Курык* | Құрық*| (Мангистауск* + район*) | (Маңғыстау*+ аудан*)|Шетпе* | (Мунайлинск* + район*)| (Мұнайлы* + аудан*)| (Тупкараганск* + район*)| (Түпқараған* + аудан*) | Форт-Шевченко* | (Жетібай* + ауыл*) | (сел* + Тенге) | (Теңге* + ауыл*)',
                    'attrSearch': false,
                    'tone': null
                }
            };
        }
        const settings = {
            method: 'POST',
            body: JSON.stringify(ajxParams),
            headers: {
                'Accept': 'application/json, text/plain, **/**',
                'Content-Type': 'application/json;charset=utf-8'
            }
        };
        const result = {
            postLine: 'https://mm.csi.kz/app-py/report-form',
            settings: settings
        };

        return result;
    }

    private async loadData() {
        try {
            const response = await fetch(this.selectedMap.postLine, this.selectedMap.settings);
            if (!response.ok) {
                const message = `>>>An error has occured>>>: ${response.status}`;
                return message;
            }
            const data = await response.json();
            if (data !== null || data.data.length !== 0) {
                data.data.sort(this.sortByField('bDate'));
            }
            this.datas = { type: 'chart-tone-line', data: data.data };
        } catch (e) {
            return e;
        }
        // console.log('result=', this.datas);
        return this.datas;
    }

    private sortByField(field: string): any {
        return (a: { [x: string]: number }, b: { [x: string]: number }) => (a[field] > b[field] ? 1 : -1);
    }

    public get indicatorKeys(): string[] {
        return [];
    }
}